import { FC } from "react";

interface Props {
  kind: "new";
}

const LabelBadge: FC<Props> = ({ kind }) => {
  return (
    <div className="text-white bg-black uppercase grid place-items-center place-content-center text-tiny py-4 px-6 rounded-xxs">
      {kind}
    </div>
  );
};
export default LabelBadge;
